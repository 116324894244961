import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { BlockItem } from "components/BlockItem";
import cx from "classnames";

export const PricingItem = ({
  label,
  price,
  perPerson,
  japaneseOnly,
  displayCurrency,
}) => {
  const { t } = useTranslation("homepage");
  const currency = japaneseOnly
    ? "currency_jpy"
    : !Boolean(displayCurrency)
    ? "currency_idr"
    : displayCurrency === "IDR"
    ? "currency_idr"
    : "currency_usd";

  const formattedPrice = t(currency, {
    nominal: price.toLocaleString({
      style: "currency",
      currency: !Boolean(displayCurrency) ? "IDR" : displayCurrency,
    }),
  });

  return (
    <BlockItem label={label}>
      {formattedPrice}++ 
      {perPerson && (
        <span className="tracking-2 font-normal text-12-22 text-gray-dark ml-5">
          / {t("person")}
        </span>
      )}
    </BlockItem>
  );
};

export const Pricing = ({ children, bordered, info }) => {
  const { t } = useTranslation("homepage");
  return (
    <div
      className={cx("bg-white", {
        "shadow-booking-form border border-gray-light p-20": bordered,
      })}>
      <div className="py-6 mb-20 border-b border-gray-light">
        <p className="font-mulish text-gold font-bold text-12 uppercase tracking-8">
          {t("Pricing")}
        </p>
      </div>
      <div>{children}</div>
      {info && (
        <span className="block font-mulish text-12-20 text-gray-dark mt-10">
          {info}
        </span>
      )}
    </div>
  );
};

Pricing.defaultProps = {
  bordered: true,
};
